@import '../../../styles/customMediaQueries.css';

.root {}

.content {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  /* align-items: center; */
  position: relative;
}

.avatar {
  flex-shrink: 0;
  margin-right: 24px;
  box-shadow: 4px 4px 0px 0px #DFC6B8;
  @media (max-width: 767px) {
    width: 80px;
    height: 80px;
    margin-right: 16px;
  }
}

.info {
  flex: 1;
  position: relative;
}
.desktopContact{
  @media (max-width: 767px) {
    display: none;
  }
}
.mobileContact{
  @media (min-width: 768px) {
    display: none;
  }
}
.linksContact{
  @media (max-width: 767px) {
    position: absolute;
    top: 12px;
    right: 0px;
  }
}
.mobileContent{
  color: #000;
  text-decoration: underline;
  @media (min-width: 768px) {
    display: none;
  }
  & .linkSeparator{
    color: #000;
  }
}
.desktopContent{
  @media (max-width: 767px) {
    display: none;
  }
}
.heading {
  composes: p from global;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.link {
  /* white-space: nowrap; */
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */

  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: inline;

    &::before {
      content: ' ';
    }
  }
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.links {
  margin-top: 8px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}
.viewRentMobile{
  @media (min-width: 768px) {
    display: none;
  }
}
.viewRentDesktop{
  position: absolute;
  position: absolute;
    top: -47px;
    right: 0;
   & p{
    margin: 0;
    & a{
      background: #E27757;
      color: #fff;
      padding: 8px 16px;
      border-radius: 4px;
      font-family: Poppins;
font-size: 14px;
line-height: 27px;
text-align: center;

      &:hover{
        text-decoration: unset;
        box-shadow: 4px 4px 0px 0px #E0D687;
      }
    }
   }
  @media (max-width: 767px) {
    display: none;
  }
}
.editProfileDesktop {
  composes: p from global;
  display: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  composes: p from global;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin: 0;
  }
}

.ratings{
  display: block;
  margin-top: 2px;
}